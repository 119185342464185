
































import { Component, mixins, Prop, Watch } from 'nuxt-property-decorator';
import { onKeyStroke } from '@vueuse/core';
import { Portal } from 'portal-vue';
import { FocusTrap } from 'focus-trap-vue';

import LockBodyScrollMixin from '@/mixins/lock-body-scroll';
import { IconClose } from '@/icons';

@Component({
  components: {
    Portal,
    IconClose,
    FocusTrap,
  },
  inheritAttrs: false,
})
export default class Modal extends mixins(LockBodyScrollMixin) {
  @Prop(Boolean)
  open!: boolean

  @Prop({ type: String, required: true })
  labelledBy!: string

  @Prop(Boolean)
  alertDialog!: boolean

  @Prop({
    type: String,
    default: 'white',
    validator (value) {
      return [
        'dark', 'light', 'white', 'pink-100', 'red-100', 'yellow-100',
      ].includes(value);
    },
  })
  variant!: string;

  focusIsTrapped = false;

  @Watch('open')
  whenOpenChanges (open) {
    open ? this.onOpen() : this.onClose();
  }

  @Watch('$route')
  onRouteChange () {
    this.onClose();
  }

  mounted () {
    onKeyStroke('Escape', this.emitClose);

    if (this.open)
      this.onOpen();
  }

  onOpen () {
    this.focusIsTrapped = true;
    this.lockScroll(true);
  }

  onClose () {
    this.focusIsTrapped = false;
    this.lockScroll(false);
  }

  emitClose () {
    this.$emit('close');
  }
}
